import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';

import AuthContext from "../helpers/AuthContext";
import React, { useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Navbar from "./navbar";
import Footer from "./footer";

function Home() {
  const { isLoggedIn, userId, checkLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkLoggedIn().then((res) => {
      if (!res) {
        navigate("/login");
      }
    });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <Container className="App">
      <Navbar></Navbar>
      <Container className="p-4">
        <Row>
          <Col className="text-center my-4">
            <Image src="/logo.png" width={300} rounded />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center my-4">
              Welcome to Madevo AI & IoT App.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Home;
