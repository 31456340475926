import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from "../helpers/AuthContext";

import axios from "axios";
import moment from 'moment';

function SystemSettings(props) {
    const { checkLoggedIn, userId } = useContext(AuthContext);

    const [saving, setSaving] = useState(false)
    const [systemSettings, setSystemSettings] = useState({ meta: {} });
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const { t } = useTranslation();

    function loadSystemSettings() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "admin/settings";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setSystemSettings(res.data.settings);
                return res.data.settings;
            }
            return {};
        })
            .catch((e) => {
                setErrorMessage(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        saveSystemSettings()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn()
            })
            .catch((e) => {
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        props.handleShow(false);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        let val = e.target.value;

        if (field.includes('config')) {
            subfield = field.replace("config.", "");
            field = 'config';
        }

        if (e.target.type === "number") {
            val = parseFloat(val);
        }

        if (subfield === undefined) {
            systemSettings[field] = val;
        } else {
            systemSettings[field][subfield] = val;
        }
    }

    function saveSystemSettings() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/settings";
        return axios.put(ENDPOINT, systemSettings, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                setErrorMessage("");
                axios.get(ENDPOINT, {
                    params: { id: userId },
                    withCredentials: true,
                })
                    .then((res) => {
                        axios.get(ENDPOINT, {
                            params: { id: my_uid },
                            withCredentials: true,
                        })
                            .then((l_res) => {
                                console.log(l_res.data.user.role.name);
                                const canManageSystemSettings = l_res.data.user.role.name.toLowerCase() === 'admin';
                                if (canManageSystemSettings) {
                                    loadSystemSettings();
                                }
                            })
                    })
                    .catch((err) => {
                        // TODO: Error handling
                        console.error(err.response.data.message);
                    })
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("confirm.modify.settings")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Edit System Settings")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage.length > 0 &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="danger">{errorMessage}</Badge>
                            </Col>
                        </Row>

                    }
                    {systemSettings && systemSettings.config &&
                        <Row>
                            <Col className="d-flex justify-content-start mb-4">
                                <Badge bg="secondary">{t("Updated At")}: {moment(systemSettings.updated_at).format('MMMM Do YYYY, H:mm')}</Badge>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            {systemSettings && systemSettings.config &&
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridName">
                                            <Form.Label>{t("Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="name" type="text" defaultValue={systemSettings.name} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridUsers">
                                            <Form.Label>{t("Max Users")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="config.max_users" type="number" min="0" defaultValue={systemSettings.config.max_users} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridDS">
                                            <Form.Label>{t("Max Datasources")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="config.max_datasources" type="number" min="0" defaultValue={systemSettings.config.max_datasources} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridFileSize">
                                            <Form.Label>{t("Max Total File Size")} (MB) <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="config.max_total_file_size_mb" type="number" min="0" defaultValue={systemSettings.config.max_total_file_size_mb} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridQueries">
                                            <Form.Label>{("Max Chatbot Queries per User per Month")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="config.max_llm_queries_per_user" type="number" min="0" defaultValue={systemSettings.config.max_llm_queries_per_user} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridForecast">
                                            <Form.Label>{t("Max Forecasting Data Points per User per Month")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="config.max_forecasting_data_points" type="number" min="0" defaultValue={systemSettings.config.max_forecasting_data_points} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridAlerts">
                                            <Form.Label>{t("Max Active Alerts per User")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="config.max_active_alerts_per_user" type="number" min="0" defaultValue={systemSettings.config.max_active_alerts_per_user} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                </Form>
                            }
                            {!systemSettings &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SystemSettings;