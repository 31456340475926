import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useState } from "react";
import { ArrowLeftCircle, CheckLg, SignDoNotEnter } from 'react-bootstrap-icons';

import axios from "axios";

import useForm from "../utilities/useForm";
import validator from "validator";
import { Link } from "react-router-dom";

const ENDPOINT = process.env.REACT_APP_API_URL + "user/get-reset-password-link";

function ForgotPassword() {
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    function onEmailChange(v) {
        const val = v.target.value;
        setEmail(val)
        setValidEmail(validator.isEmail(val));
        if (invalidEmail) {
            setInvalidEmail(false);
        }
    }

    function callback(data) {
        return axios.post(ENDPOINT, data)
            .then((res) => {
                if (res.data.error) {
                    setInvalidEmail(true);
                } else {
                    setEmailSent(true);
                }
            })
            .catch((e) => {
                if (e.response && e.response.data.error) {
                    setInvalidEmail(true);
                }
            });
    }
    const additionalData = null;
    const { handleSubmit, status, message } = useForm({
        additionalData,
        callback,
    });

    return (
        <Container className="App">
            <Container className="p-4">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card border="dark shadow">
                            <Card.Header>Forgot Password</Card.Header>
                            <Card.Body>
                                {invalidEmail === true &&
                                    <Alert key="danger" variant="danger">
                                        <i>Invalid email address</i>
                                    </Alert>
                                }
                                {emailSent === true &&
                                    <Alert key="success" variant="success">
                                        <i>Reset link emailed successfully</i>
                                    </Alert>
                                }
                                <Row>
                                    <Col>
                                        <p className="text-primary">Please provide your account email and we will send you a unique reset password link.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form
                                            action={ENDPOINT}
                                            onSubmit={handleSubmit}
                                            method="POST"
                                            className="pb-2 mb-2"
                                        >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="email" type="email" placeholder="Enter email"
                                                        value={email} onChange={onEmailChange}
                                                        className={email.length === 0 ? "" : validEmail ? "is-valid" : "is-invalid"}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <div className="d-flex justify-content-between">
                                                <Link to={"/login"} className="btn btn-outline-info">
                                                    <ArrowLeftCircle /> Back
                                                </Link>
                                                {validEmail !== true &&
                                                    <Button variant="danger" disabled type="button" className="fw-bolder">
                                                        <SignDoNotEnter />
                                                    </Button>
                                                }
                                                {validEmail === true &&
                                                    <Button variant="primary" type="submit" className="fw-bolder">
                                                        <CheckLg /> Send Link
                                                    </Button>
                                                }
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ForgotPassword;