import { Navigate, createBrowserRouter } from "react-router-dom";

import Error from "../Components/error";

import Login from "../Components/login";
import Home from "../Components/home";
import Dashboard from "../Components/dashboard";
import Profile from "../Components/profile";
import Register from "../Components/register";
import ForgotPassword from "../Components/forgot_password";
import ResetPassword from "../Components/reset_password";

import Accounts from "../Components/accounts";
import AccountList from "../Components/accounts/account_list";
import RoleList from "../Components/accounts/role_list";

import Companies from "../Components/companies";
import CompanyList from "../Components/companies/company_list";
import CompanyEdit from "../Components/companies/company_edit";

import ActivateAccount from "../Components/activate_account";

import PlanList from "../Components/plans/list";
import SubscriptionList from "../Components/subscriptions/list";
import PlanEdit from "../Components/plans/edit";
import Plans from "../Components/plans";
import Subscriptions from "../Components/subscriptions";
import SubscriptionEdit from "../Components/subscriptions/edit";
import Datasources from "../Components/datasources";
import DatasourceList from "../Components/datasources/list";
import DatasourcePreview from "../Components/datasources/preview";
import Assistant from "../Components/assistant";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <Error />,
    },
    {
        path: "/register",
        element: <Register />,
        errorElement: <Error />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        errorElement: <Error />,
    },
    {
        path: "/reset-password/:token/:email",
        element: <ResetPassword />,
        errorElement: <Error />,
    },
    {
        path: "/activate-account/:token/:email",
        element: <ActivateAccount />,
        errorElement: <Error />,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        errorElement: <Error />,
    },
    {
        path: "/profile",
        element: <Profile />,
        errorElement: <Error />,
    },
    {
        path: "/accounts",
        element: <Accounts />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Navigate to={"/accounts/list"} replace />,
            },
            {
                path: "/accounts/list",
                element: <AccountList />,
                errorElement: <Error />,
            },
            {
                path: "/accounts/roles",
                element: <RoleList />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/companies",
        element: <Companies />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Navigate to={"/companies/list"} replace />,
            },
            {
                path: "/companies/list",
                element: <CompanyList />,
                errorElement: <Error />,
            },
            {
                path: "/companies/edit/:id",
                element: <CompanyEdit />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/plans",
        element: <Plans />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Navigate to={"/plans/list"} replace />,
            },
            {
                path: "/plans/list",
                element: <PlanList />,
                errorElement: <Error />,
            },
            {
                path: "/plans/edit/:id",
                element: <PlanEdit />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/subscriptions",
        element: <Subscriptions />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Navigate to={"/subscriptions/list"} replace />,
            },
            {
                path: "/subscriptions/list",
                element: <SubscriptionList />,
                errorElement: <Error />,
            },
            {
                path: "/subscriptions/edit/:id",
                element: <SubscriptionEdit />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/datasources",
        element: <Datasources />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Navigate to={"/datasources/list"} replace />,
            },
            {
                path: "/datasources/list",
                element: <DatasourceList />,
                errorElement: <Error />,
            },
            {
                path: "/datasources/preview/:id",
                element: <DatasourcePreview />,
                errorElement: <Error />,
            },
        ],
    },
    {
        path: "/assistant",
        element: <Assistant />,
        errorElement: <Error />,
    },
]);

export default router;
