import axios from "axios";
import { useState } from "react";

function useForm({ additionalData, callback }) {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");
    setMessage("");

    const finalFormEndpoint = e.target.action;
    const data = Array.from(e.target.elements)
      .filter((input) => input.name)
      .reduce(
        (obj, input) => Object.assign(obj, { [input.name]: input.value }),
        {}
      );

    if (additionalData) {
      Object.assign(data, additionalData);
    }

    let promise = undefined;
    if (callback !== undefined) {
      promise = callback(data);
    } else {
      promise = axios.post(finalFormEndpoint, data);
    }
    promise
      .then((response) => {
        if (response.error) {
          setStatus("error");
          const msg = response.message;
          setMessage(msg);
        }

        return response;
      })
      .then((res) => {
        const msg = res.message || res.data.message;
        setMessage(msg);
        setStatus("success");
        setTimeout(() => {
          setMessage("");
          setStatus("loading");
        }, 1000);
      })
      .catch((err) => {
        const msg = err.response ? err.response.data.message : err.message ? err.message : toString(err);
        setMessage(msg);
        setStatus("error");
        setTimeout(() => {
          setMessage("");
          setStatus("loading");
        }, 2000);
      });
  };

  return { handleSubmit, status, message };
}

export default useForm;
