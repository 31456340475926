import axios from 'axios';
import { Check } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function ConfirmMessage(props) {

    function handleConfirm(val) {
        var l_what = Object.assign({}, props.what)
        l_what.selected = val;

        var payload = {
            "conversation_id": props.id,
            "idx": `${props.idx}`,
            "what": l_what,
        }

        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/chat/confirm";
        return axios.post(INFO_ENDPOINT, payload, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error) {
                props.reLoad(props.id);
            }
        });

    }

    return <Alert variant='secondary'>
        {props.what.confirm}
        <hr />
        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className='mx-2' disabled={props.what.selected} onClick={() => handleConfirm("yes")} >
            {props.what.selected == "yes" &&
                <Check /> 
            }
            {props.what.yes}
          </Button>
          <Button variant="outline-secondary" disabled={props.what.selected} onClick={() => handleConfirm("no")}>
            {props.what.selected == "no" &&
                <Check /> 
            }
            {props.what.no}
          </Button>
        </div>
    </Alert>
}

export default ConfirmMessage;