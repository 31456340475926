import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.css";

import { RouterProvider } from "react-router-dom";
import router from "./router/router";

import { AuthProvider } from "./helpers/AuthProvider";

import "./i18n";
import "moment/locale/el";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
