import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useEffect, useContext } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import { Outlet } from 'react-router-dom';
import AuthContext from '../../helpers/AuthContext';

import { useTranslation } from 'react-i18next';

function Datasources() {

    const { userRole, checkLoggedIn } = useContext(AuthContext);

    const { t } = useTranslation();

    useEffect(() => {
        if (userRole === undefined || userRole.access === undefined) {
            checkLoggedIn();
        }
    })

    return (
        <Container className="App">
            <Navbar></Navbar>
            <Container className="p-0 p-md-4 container-fluid mb-5">
                <Row>
                    <Col>
                        <Card border="dark shadow">
                            <Card.Header>{t("Datasource Management")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Outlet />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Container>
    );
}

export default Datasources;
