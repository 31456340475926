import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

import { PencilSquare, PlusCircle, Trash3 } from 'react-bootstrap-icons';

import { useContext, useEffect, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import RoleEdit from './role_edit';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

function RoleList() {
    const { userRole, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [role_id, setRoleId] = useState("");

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [toDelete, setToDelete] = useState("");

    const { t } = useTranslation();

    function handleEditButton(id) {
        setRoleId(id)
        setShowEdit(true);
    }

    function handleNewButton(id) {
        setRoleId("")
        setShowEdit(true);
    }

    function loadListData() {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/roles";
        axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setRoles(res.data.roles);
            }
            setIsLoading(false);
        });
    }

    function handleDeleteButton(id) {
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/role";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData();
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const canManageRoles = res.data.user.role.access.manage_roles;
                        if (canManageRoles) {
                            loadListData();
                        } else {
                            navigate("/accounts");
                        }
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                    })
            } else {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts
    return (
        <>
            <div className='d-flex justify-content-between my-2'>
                <div></div>
                {isLoading &&
                    <Spinner animation="border" role="status" size='sm' variant='primary' className='m-2'>
                        <span className="visually-hidden">{t("Loading")}...</span>
                    </Spinner>
                }
                <Button variant='outline-success' className='btn-sm' onClick={handleNewButton}>
                    <PlusCircle /> {t("New Role")}
                </Button>
            </div>
            <ListGroup>
                {
                    roles.map((item) => {
                        return (<ListGroup.Item key={item._id}>
                            {item.name === 'admin' &&
                                <div className='d-flex justify-content-between'>
                                    <Badge bg="danger" className='mx-1 my-2'>{item.name}</Badge>
                                    {item.is_default &&
                                        <Badge pill bg="secondary" className='my-2'>
                                            {t("default")}
                                        </Badge>
                                    }
                                    <ButtonGroup aria-label="Actions" className=''>
                                        <Button variant="outline-primary" className='btn-sm px-2' onClick={() => handleEditButton(item._id)}><PencilSquare /></Button>
                                    </ButtonGroup>
                                </div>
                            }
                            {(item.name === 'dummy') &&
                                <div className='d-flex justify-content-between'>
                                    <Badge bg="warning" className='mx-1 my-2'>{item.name}</Badge>
                                    {item.is_default &&
                                        <Badge pill bg="secondary" className='my-2'>
                                            {t("default")}
                                        </Badge>
                                    }
                                    <ButtonGroup aria-label="Actions" className=''>
                                        <Button variant="outline-primary" className='btn-sm px-2' onClick={() => handleEditButton(item._id)}><PencilSquare /></Button>
                                    </ButtonGroup>
                                </div>
                            }
                            {['manager', 'agent'].includes(item.name) &&
                                <div className='d-flex justify-content-between'>
                                    <Badge bg="success" className='mx-1 my-2'>{item.name}</Badge>
                                    {item.is_default &&
                                        <Badge pill bg="secondary" className='my-2'>
                                            {t("default")}
                                        </Badge>
                                    }
                                    <ButtonGroup aria-label="Actions" className=''>
                                        <Button variant="outline-primary" className='btn-sm px-2' onClick={() => handleEditButton(item._id)}><PencilSquare /></Button>
                                    </ButtonGroup>
                                </div>
                            }
                            {!['admin', 'dummy', 'manager', 'agent'].includes(item.name) && 
                                <div className='d-flex justify-content-between'>
                                    <Badge bg="primary" className='mx-1 my-2'>{item.name}</Badge>
                                    {item.is_default &&
                                        <Badge pill bg="secondary" className='my-2'>
                                            {t("default")}
                                        </Badge>
                                    }
                                    <ButtonGroup aria-label="Actions" className=''>
                                        <Button variant="outline-primary" className='btn-sm px-2' onClick={() => handleEditButton(item._id)}><PencilSquare /></Button>
                                        <Button variant="outline-danger" className='btn-sm px-2' onClick={() => handleDeleteButton(item._id)}><Trash3 /></Button>
                                    </ButtonGroup>
                                </div>
                            }

                        </ListGroup.Item>)
                    }
                    )
                }
            </ListGroup>
            {showEdit &&
                <RoleEdit show={showEdit} handleShow={setShowEdit} role_id={role_id} setRoleId={setRoleId} reLoad={loadListData} />
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.role")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}

export default RoleList;
