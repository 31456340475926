/* eslint-disable react-hooks/exhaustive-deps */
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DateTimeField from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import { LineChart } from '@mui/x-charts/LineChart';

import { Check, Eye, PencilSquare, PlusCircle, Trash3, ArrowClockwise, ArrowLeftCircle } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';

import { useContext, useEffect, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

function DatasourcePreview() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const routerParams = useParams();

    const [currentDatasource, setCurrentDatasource] = useState({});
    const [data, setData] = useState([]);
    const [plotData, setPlotData] = useState([]);
    const [asset, setAsset] = useState("");
    const [value, setValue] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [meta, setMeta] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [showTable, setShowTable] = useState(false);

    const { t } = useTranslation();

    const PAGE_SIZES = [
        10, 50, 100,
    ];

    function loadDataSource(id) {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
        return axios.get(LIST_ENDPOINT, {
            params: { id: id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCurrentDatasource(res.data.datasource);
                return res.data.datasource;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            }).finally(() => setIsLoading(false));
    }

    function loadListData(id, page, pageSize, asset, value, from_str, to_str) {
        if (asset === "" || value === "") return;
        setIsLoading(true);

        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/data/plot";

        return axios.post(LIST_ENDPOINT,
            {
                datasource_id: id,
                asset: asset,
                value: value,
                meta: meta,
                from: from_str,
                to: to_str,
                page: page,
                page_size: parseInt(pageSize),
            },
            {
                withCredentials: true,
            }).then((res) => {
                if (res && res.data) {
                    setData(res.data.data.slice(page*parseInt(pageSize), (page+1)*parseInt(pageSize)));
                    setPlotData(res.data.data)
                }
                setIsLoading(false);
            });
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const canManageDatasources = res.data.user.role.access.manage_all_datasources || res.data.user.role.access.manage_self_datasources || true;
                        loadDataSource(routerParams.id);
                        var l_to = moment.parseZone(Date.now());
                        var to_str = l_to.format("YYYY-MM-DD HH:mm:ss");
                        var from_str = l_to.subtract(24, 'hours').format("YYYY-MM-DD HH:mm:ss");
                        setTo(to_str);
                        setFrom(from_str);
                        loadListData(routerParams.id, page, pageSize, asset, value, from_str, to_str);
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                    })
            } else {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    function handleChangeDT(val, what) {
        if (!val) return;
        var fromdt;
        var todt;
        var from_str = from;
        var to_str = to;
        if (what === "from") {
            fromdt = moment.parseZone(val)
            todt = moment.parseZone(to)
            from_str = fromdt.format("YYYY-MM-DD HH:mm:ss");
            setFrom(from_str);
            if (fromdt.isSameOrAfter(todt)) {
                setTo(fromdt.add(100, 'seconds').format("YYYY-MM-DD HH:mm:ss"));
            }
        } else {
            fromdt = moment.parseZone(from)
            todt = moment.parseZone(val)
            to_str = todt.format("YYYY-MM-DD HH:mm:ss");
            setTo(to_str);
            if (fromdt.isSameOrAfter(todt)) {
                setFrom(todt.subtract(100, 'seconds').format("YYYY-MM-DD HH:mm:ss"));
            }
        }
        loadListData(routerParams.id, page, pageSize, asset, value, from_str, to_str);
    }

    return (
        <>
            <div className='d-flex justify-content-between my-2'>
                <Button variant='outline-primary' className='btn-sm' onClick={() => navigate("/datasources/list")}>
                    <ArrowLeftCircle /> {t("Back to Datasources")}
                </Button>
                {isLoading &&
                    <Spinner animation="border" role="status" size='sm' variant='primary'>
                        <span className="visually-hidden">{t("Loading")}...</span>
                    </Spinner>
                }
                <h4>{t("Preview")}</h4>
            </div>
            <Card>
                <Row className="m-3">
                    <Form.Group as={Col} controlId="formAsset" md={4}>
                        <Form.Label>{t("Asset")}: </Form.Label>
                        <Form.Select name="asset" value={asset} onChange={(e) => { setAsset(e.target.value); loadListData(routerParams.id, page, pageSize, e.target.value, value, from, to); }} >
                            <option value=""></option>
                            {currentDatasource && currentDatasource.config &&
                                currentDatasource.config.assets.map((ps) => {
                                    return (<option value={ps} key={ps}>
                                        {ps}
                                    </option>);
                                })
                            }
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formValue" md={4}>
                        <Form.Label>{t("Value")}: </Form.Label>
                        <Form.Select name="value" value={value} onChange={(e) => { setValue(e.target.value); loadListData(routerParams.id, page, pageSize, asset, e.target.value, from, to); }} >
                            <option value=""></option>
                            {currentDatasource && currentDatasource.config &&
                                currentDatasource.config.values.map((ps) => {
                                    return (<option value={ps} key={ps}>
                                        {ps}
                                    </option>);
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Row className="m-3">
                    {from.length > 0 &&
                        <Form.Group as={Col} controlId="formFrom" md={4}>
                            <Form.Label className='me-2'>{t("From")}: </Form.Label>
                            <DateTimeField
                                aria-label="Date and time" type="datetime-local"
                                format="y-MM-dd HH:mm"
                                value={moment.parseZone(from).toDate()}
                                dateTime={moment.utc().format("YYYY-MM-DD HH:mm:ss")}
                                onChange={(val) => handleChangeDT(val, "from")}
                                locale='utc'
                                clearIcon={false}
                            />
                        </Form.Group>
                    }
                    {to.length > 0 &&
                        <Form.Group as={Col} controlId="formTo" md={4}>
                            <Form.Label className='me-2'>{t("To")}: </Form.Label>
                            <DateTimeField
                                aria-label="Date and time" type="datetime-local"
                                format="y-MM-dd HH:mm"
                                value={moment.parseZone(to).toDate()}
                                dateTime={moment.utc().format("YYYY-MM-DD HH:mm:ss")}
                                onChange={(val) => handleChangeDT(val, "to")}
                                clearIcon={false}
                            />
                        </Form.Group>
                    }
                </Row>
                <Row className="m-3">
                    <Form.Group as={Col} controlId="formPageSize" md={2}>
                        <Form.Label>{t("Rows per Page")}</Form.Label>
                        <Form.Select name="page_size" value={pageSize} onChange={(e) => { loadListData(routerParams.id, page, e.target.value, asset, value, from, to); setPageSize(e.target.value); }} >
                            {
                                PAGE_SIZES.map((ps) => {
                                    return (<option value={ps} key={ps}>
                                        {ps}
                                    </option>);
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Card.Body className='m-3'>
                    <LineChart className='w-100 justify-content-center'
                        xAxis={[
                            { 
                                data: plotData.map((item, idx) => moment.parseZone(item.timestamp)),
                                scaleType: "utc",
                                tickNumber: 3,
                                valueFormatter: (v, c) => {
                                    return moment.parseZone(v).format("YYYY-MM-DD@HH:mm:ss.SS");
                                }
                            },
                        ]}
                        series={[
                            {
                                curve: "linear",
                                showMark: false,
                                data: plotData.map((item) => parseFloat(item.values[value])),
                                valueFormatter: (v) => {
                                    return `${value}: ${v}`;
                                }
                            },
                        ]}
                        width={600}
                        height={400}
                    />
                </Card.Body>

                <Card.Body className='m-3'>
                    <Form.Group as={Col} className="">
                        <Form.Check className="switch-success" id="showTable" name="showTable" type="switch" label={t("Show Table")} defaultChecked={showTable} onChange={() => setShowTable(!showTable)} />
                    </Form.Group>
                </Card.Body>

                {showTable &&
                    <Card.Body>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Asset</th>
                                    <th>{value}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((o) => {
                                        return <tr>
                                            <td>{moment.parseZone(o.timestamp).format("YYYY-MM-DD @ HH:mm:ss.SS")}</td>
                                            <td>{o.metadata}</td>
                                            <td>{o.values[value]}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        <Pagination className='w-100 justify-content-center'>
                            <Pagination.Prev disabled={page == 0} onClick={() => { loadListData(routerParams.id, page - 1, pageSize, asset, value, from, to); setPage(page - 1); }} />
                            <Pagination.Next onClick={() => { loadListData(routerParams.id, page + 1, pageSize, asset, value, from, to); setPage(page + 1); }} />

                        </Pagination>
                    </Card.Body>
                }
            </Card>
        </>
    );
}

export default DatasourcePreview;

