import { createContext } from 'react';

// Create a context with default values
const AuthContext = createContext({
    isLoggedIn: false,
    userId: "",
    userRole: {},
    login: (email, password) => new Promise(),
    register: (email, password) => new Promise(),
    logout: () => new Promise(),
    checkLoggedIn: () => new Promise(),
});

export default AuthContext;
