import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';

import { CheckLg } from 'react-bootstrap-icons';

import useForm from "../utilities/useForm";
import AuthContext from "../helpers/AuthContext";

import Footer from "./footer";

const FORM_ENDPOINT = process.env.REACT_APP_API_URL + "user/login";

function Login() {
  const navigate = useNavigate();
  const { login, checkLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    checkLoggedIn().then((res) => {
      if (res) {
        navigate("/");
      }
    });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const additionalData = {
    sent: new Date().toISOString(),
  };

  function callback(data) {
    return login(data.email, data.password).then((res) => {
      if (!res.error) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
      return res;
    });
  }

  const { handleSubmit, status, message } = useForm({
    additionalData,
    callback,
  });

  return (
    <Container className="pt-4">
      <Row className="mt-4">
        <Col xl={{ span: 4, offset: 4 }}>
          <Card className="p-4">
            <Card.Img variant="top" src="logo.png" className="w-2 mx-auto"></Card.Img>
            <Form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
              style={{ borderBottom: "1px solid rgba(0,0,0,0.175)" }}
              className="pb-2 mb-2"
            >
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupMessage">
                {status.length > 0 && status !== 'loading' && (
                  <Alert
                    key={status === "success" ? "success" : "danger"}
                    variant={status === "success" ? "success" : "danger"}
                  >
                    <i>{message}</i>
                  </Alert>
                )}
              </Form.Group>
              <div className="d-grid">
                <Button variant="primary" type="submit" className="fw-bolder">
                  <CheckLg />
                </Button>
              </div>
            </Form>
            <Row>
              <Col>
                <Link className="link-secondary" to="/forgot-password">Forgot Password?</Link>
              </Col>
            </Row>
            <Row>
              <Col className="text-secondary">
                Don't have an account? <Link className="link-secondary" to="/register">Register</Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container >
  );
}

// TODO: Add Captcha

export default Login;
